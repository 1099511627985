var isRTL = ($('html').attr('dir') == 'rtl') ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height(),
    popCls;

$(function() {
    browserDetect();
    ChangeToSvg();
    onclickfunctions();
    bindPopupEve();
    carSlider();
    validation('.form-validation');
    fileuploader('.js-fileuploder');
    boxslider('.js-custom-slider',3,1);
    boxslider('.js-monial-carousel',1,1);
    boxslider('.js-neuste-carousel',4,1);
});

$(window).on('load', function() {
    // Do after Page ready
    doOnLoad();
});

$(window).on('resize orientationchange', function() {
    // Do on resize
    winWidth = $(window).width(),
    winHeight = $(window).height();
});

$(window).on('scroll', function() {
    //Do on Scroll
});

$(document).keyup(function(e) {
    if (e.keyCode == 27) {
        //Do on ESC press
        closePopup();
    }
});

function doOnLoad() {
    //OnLoad Function
    $('.loaderscreen').fadeOut();
    initCarousel('.owl-carousel');
    initSelect2('.js-select2');
    initSelect2Img('.js-select2img');
    iniIsotope();
    setTimeout(function() {
        addVideoPlugin();
    }, 200);
    boxslider('.js-custom-slider',3,1);
    boxslider('.js-monial-carousel',1,1);
    boxslider('.js-neuste-carousel',4,1);
}

function onclickfunctions(){
    $('body').on('click', '.js-menubtn:not(.is--active)', function() {
        sideMenuOpen();
    }).on('click', '.js-menubtn.is--active', function() {
        sideMenuClose();
    }).on('click', '.js-menuclose', function() {
        sideMenuClose();
    }).on('click touchstart', '.js-defaultOverlay', function() {
        sideMenuClose();
    }).on('change','.js-dealercheck input[type=radio]',function(){
        var $this = $(this);
        $this.parents('.popup').toggleClass('privateCustomerPopup', $this.checked && $this.id == 'ich-bin1');
    });
}

function browserDetect() {
    navigator.sayswho = (function() {
        var ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    $('body').addClass(navigator.sayswho);
}

function openPopup(target) {
    var popnam = target;
    popCls = popnam.substring(1, popnam.length);
    $('body').addClass(popCls);
    $('html').addClass('popup-is-active');
    $(target).show();
    $(target).closest('.c-popup').show();
    setTimeout(function() {
        $(target).addClass('active');
        $(target).closest('.c-popup').addClass('popup--open');
        if ($(target).find('.plyr').length) {
            var videoInstance = $(target).find('.plyr').attr('data-video-instance');
            players[videoInstance].play();
        }
        // Play Video
        if (winWidth > 1024 && $(target).find('.plyr').length) {
            var videoInstance = $(target).find('.js-video').attr('data-video-instance');
            if (players[videoInstance]) {
                players[videoInstance].play();
            }
        } else if ($(target).find('.js-video').length) {
            $('.popup.active iframe')[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        }
    }, 10);
}

function closePopup() {
    $('body').removeClass(popCls);
    if ($('.c-popup .active').length) {
        // Pause Video In Popup
        if (winWidth > 1024 && $('.c-popup .active .plyr').length) {
            var videoInstance = $('.c-popup .active .plyr').attr('data-video-instance');
            if (players[videoInstance]) {
                players[videoInstance].pause();
            }
        } else if ($('.c-popup .active .js-video').length) {
            $('.popup.active iframe')[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
        // Close Popup
        $('.c-popup .active').removeClass('active');
        $('.c-popup').removeClass('popup--open');
        setTimeout(function() {
            $('.c-popup .popup').hide();
            $('.c-popup').hide();
            $('html').removeClass('popup-is-active');
        }, 310);
    }
}

function addVideoPlugin() {
    if (winWidth > 1024 && $('.js-video').get(0)) {
        var plyrScriptElement = document.createElement('script');
        if (isRTL) {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + 'js/plyr.min.js');
        } else {
            plyrScriptElement.setAttribute('src', customVariables.baseURL + 'js/plyr.min.js');
        }
        plyrScriptElement.setAttribute('async', 'true');
        document.body.appendChild(plyrScriptElement);
    } else {
        jsVideoDirect();
    }
}

var players = [];

function jsVideo() {
    // Custom player
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            var thisParent = $(this).parent();
            players[i] = new Plyr(this, {
                playsinline: true,
            });
            thisParent.find('.plyr').attr('data-video-instance', i);
        });
    }
}

function jsVideoDirect() {
    if ($('.js-video').length) {
        $('.js-video').each(function(i) {
            $(this).attr('data-video-instance', i);
            var videoId = $(this).attr('data-plyr-embed-id');
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoId + '?rel=0&playsinline=1&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
        });
    }
}

function bindPopupEve() {
    // Popup Open
    $('.js-popup-link').click(function(e) {
        e.preventDefault();
        if ($(this).data('popup')) {
            var target = $(this).data('popup');
        } else {
            var target = $(this).attr('href');
        }
        openPopup(target);
        ChangeToSvg();
    });
    // Popup Close
    $('.js-close-popup').click(function(e) {
        e.preventDefault();
        closePopup();
    });
}

function ChangeToSvg() {
    jQuery('img.tosvg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        jQuery.get(imgURL, function(data) {
            var $svg = jQuery(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });
}

function sideMenuOpen() {
    $('.js-menubtn').addClass('is--active');
    $('.navigation').not('.is--open').addClass('is--open');
    $('body').addClass('is--menu');
}

function sideMenuClose() {
    $('.js-menubtn').removeClass('is--active');
    $('.navigation.is--open').removeClass('is--open');
    $('body').removeClass('is--menu');
}

function initCarousel(owl) {
    var $this = $(owl);
    var _self;
    if($this.length > 0) {
        for (var index = 0; index < $this.length; index++) {
            var _self = $($this[index]);
            var itemDesktop = _self.data('item-desktop');
            var itemIpadPro = _self.data('item-ipad-pro');
            var itemTablet = _self.data('item-tablet');
            var itemMobile = _self.data('item-mobile');
            _self.owlCarousel({
                loop:true,
                autoplay: false,
                smartSpeed: 1000,
                autoplaySpeed: false,
                autoplayTimeout: 2000,
                autoplayHoverPause: true,
                nav: true,
                dots: false,
                responsive : {
                    0 : {
                        items: itemMobile,
                        dots: true,
                    },
                    375 : {
                        items: itemMobile,
                        dots: false,
                    },
                    768 : {
                        items: itemTablet,
                    },
                    992 : {
                        items: itemIpadPro,
                    },
                    1200 : {
                        items: itemDesktop,
                    },
                }
            });
        }
    }
}

function initSelect2(target) {
    var target = $(target);
    target.each(function(i,e){
        var tarPar = $(e).parent('.select2-box');
        var tarPlace = $(e).data('placeholder');
        $(e).select2({
            dropdownParent: tarPar,
            minimumResultsForSearch: Infinity,
            width: '100%',
            placeholder: tarPlace,
        });
    });
}

function select2Img(target) {
    if (!target.id) {
        return target.text;
    }
    var imgPath = $(target.element).data('img');
    var $target = $(
        `<span class="selectimg"><img src="${imgPath}" /><span>${target.text}</span></span>`
    );
    ChangeToSvg();
    return $target;
}

function initSelect2Img(target) {
    var target = $(target);
    target.each(function(i,e){
        var tarPar = $(e).parent('.select2-box');
        var tarPlace = $(e).data('placeholder');
        $(e).select2({
            dropdownParent: tarPar,
            minimumResultsForSearch: Infinity,
            width: '100%',
            placeholder: tarPlace,
            templateResult: select2Img,
            templateSelection: select2Img,
        });
    });
}

function iniIsotope() {
    var filterValue = window.location.hash.split('?')[1];
    if (filterValue !== undefined) {
        $('.button-group button').removeClass('is-checked');
        $('[data-filter="'+filterValue+'"]').addClass('is-checked');
    }
    var $grid = $('.grid').isotope({
        itemSelector: '.js-isotopes',
        filter: filterValue,
        transformsEnabled: false
    });
    $('#filters').on( 'click', 'button', function() {
        var filterValue = $( this ).attr('data-filter');
        $grid.isotope({ filter: filterValue });
    });
    $('.button-group').each( function( i, buttonGroup ) {
        var $buttonGroup = $( buttonGroup );
        $buttonGroup.on( 'click', 'button', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $( this ).addClass('is-checked');
        });
    });
}

function carSlider() {
    $('.js-car-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 4000,
        dots: true,
        arrows: false,
        centerPadding: 0,
        centerMode: true,
    });
}

function boxslider(target,stshow,stscroll) {
    var $target = $(target).not('.slick-initialized');
    var sliderArrows = $target.parents('.slider-sec').find('.js-sliderArrows');
    $target.each(function(i){
        var itemDesktop = $target.data('item-desktop');
        var itemIpadPro = $target.data('item-ipad-pro');
        var itemTablet = $target.data('item-tablet');
        var itemMobile = $target.data('item-mobile');
        $target.eq(i).slick({
            infinite: true,
            slidesToShow: stshow,
            slidesToScroll: stscroll,
            appendArrows: sliderArrows,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: itemDesktop,
                }
            }, {
                breakpoint: 992,
                settings: {
                    slidesToShow: itemIpadPro,
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: itemTablet,
                }
            }, {
                breakpoint: 375,
                settings: {
                    slidesToShow: itemMobile,
                    arrows: false,
                    dots: true,
                }
            }],
        });
    });
}

function steps(parent, toshow, active) {
    $('body,html').animate({ scrollTop: 0 }, 'slow');
	if (parent !== '' && toshow !== '') {
		$(parent + '> div').hide();
		$(parent + ' ' + toshow).fadeIn();
	}
	if (active !== '') {
		$('.stepCount li').removeClass('is--active');
		$('.stepCount li').eq(active).removeClass('is--done');
		$('.stepCount li').eq(active).addClass('is--active');
		if (active > 0) {
			$('.stepCount li').eq(active).prevAll().addClass('is--done');
		} else {
			$('.stepCount li').removeClass('is--done');
		}
	} else {
		$('.stepCount li').removeClass('is--active');
	}
}

function validation(target) {
    if($(target).length){
        var $target = $(target);
        $target.each(function(i,e){
            var v = $target.eq(i).validate({
                errorElement: 'span',
                errorClass: 'invalid-field',
                highlight: function(element) {
                    $(element).parents('.form-group').addClass('invalid-field');
                },
                unhighlight: function(element) {
                    $(element).parents('.form-group').removeClass('invalid-field');
                },
                errorPlacement: function(error, element) {
                },
                // submitHandler: function(form) {
                //     if (grecaptcha.getResponse() == ''){
                //         $( '#reCaptchaError' ).html('<p>Please verify you are human</p>');
                //     } else {
                //         formSubmit(form);
                //     }
                // }
            });
        });
        $('.js-inpnum').each(function() {
            $(this).rules('add', {
                number: true,
            });
        });
    }
}

FormFilter($('.js-select-filter')[0].value);

$('.js-select-filter').on('change', function(e){
    FormFilter(e.currentTarget.value);
})

function FormFilter(value) {
    if (value == '') {
        $('div[data-filter]').addClass('is--active');
    } else {
        $('div[data-filter]').removeClass('is--active');
        $("div[data-filter*=" + value + "]").each(function(i, ele){
            $(ele).addClass('is--active');
        });
    }
}


function fileuploader(target){
    var $target = $(target);
    $target.find('input[type="file"]').change(function(e){
        var allfiles = [];
        $(this).parent('.fileuploder').find('i').remove('i');
        for(var i = 0; i < e.target.files.length; i++){
            allfiles.push(e.target.files[i].name);
            $(this).siblings('.showname').append(`<i>${allfiles[i]}</i>`);
        }
    });
}